import React from 'react'
import PortableText from './portableText'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'

export default function FAQAcordion (props) {
  const {_rawBody, title,} = props
  console.log(_rawBody[0].children[0].text)

  return (
    <Accordion allowZeroExpanded>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>{<PortableText blocks={_rawBody[0]} />}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{<PortableText blocks={_rawBody[1]} />}</AccordionItemPanel>
      </AccordionItem>
      {/* 02 */}
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>{<PortableText blocks={_rawBody[2]} />}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{<PortableText blocks={_rawBody[3]} />}</AccordionItemPanel>
      </AccordionItem>
            {/* 03 */}
            <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>{<PortableText blocks={_rawBody[4]} />}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{<PortableText blocks={_rawBody[5]} />}</AccordionItemPanel>
      </AccordionItem>
            {/* 04 */}
            <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>{<PortableText blocks={_rawBody[6]} />}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{<PortableText blocks={_rawBody[7]} />}</AccordionItemPanel>
      </AccordionItem>
            {/* 05 */}
            <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>{<PortableText blocks={_rawBody[8]} />}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{<PortableText blocks={_rawBody[9]} />}</AccordionItemPanel>
      </AccordionItem>
            {/* 06 */}
            <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>{<PortableText blocks={_rawBody[10]} />}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{<PortableText blocks={_rawBody[11]} />}</AccordionItemPanel>
      </AccordionItem>
            {/* 07 */}
            <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>{<PortableText blocks={_rawBody[12]} />}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{<PortableText blocks={_rawBody[13]} />}</AccordionItemPanel>
      </AccordionItem>
            {/* 08 */}
            <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>{<PortableText blocks={_rawBody[14]} />}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{<PortableText blocks={_rawBody[15]} />}</AccordionItemPanel>
      </AccordionItem>
            {/* 09 */}
            <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>{<PortableText blocks={_rawBody[16]} />}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{<PortableText blocks={_rawBody[17]} />}</AccordionItemPanel>
      </AccordionItem>
            {/* 10 */}
            <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>{<PortableText blocks={_rawBody[18]} />}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{<PortableText blocks={_rawBody[19]} />}</AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}
