import React from 'react'
import {graphql} from 'gatsby'
import {mapEdgesToNodes} from '../lib/helpers'
import FQA from '../components/FQA'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import FAQAcordion from '../components/FAQAcordion'

import {responsiveTitle1} from '../components/typography.module.css'

export const query = graphql`
  query SanityFqa {
    sanityFqa(title: {eq: "fqa"}) {
      title
      _rawBody
    }
  }
`

const FqaPage = props => {
  const {data, errors} = props
  const {title, text} = data.sanityFqa
  const post = data && data.sanityFqa
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  return (
    <Layout>
      <SEO title='info' />
      <Container>
        <h1 className={responsiveTitle1}>{ title }</h1>
      </Container>
      <FAQAcordion {...post} />
    </Layout>
  )
}

export default FqaPage
